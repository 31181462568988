var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('spinner-loader',{attrs:{"loading":_vm.loadingStatistics}}),(!_vm.loadingStatistics)?_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("Statistics")])]},proxy:true}],null,false,2718173420)},[_c('b-row',[_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"UserCheckIcon","statistic":_vm.statistics.totalSuggestions,"statistic-title":"Total"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"UserPlusIcon","statistic":_vm.statistics.pendingSuggestions,"statistic-title":"Pending"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"color":"success","icon":"UserCheckIcon","statistic":_vm.statistics.publishedSuggestions,"statistic-title":"published"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"color":"warning","icon":"UserMinusIcon","statistic":_vm.statistics.notPublishedSuggestions,"statistic-title":"Not published"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"UserXIcon","color":"danger","statistic":_vm.statistics.rejectedSuggestions,"statistic-title":"Rejected"}})],1)],1)],1):_vm._e(),_c('b-card',{attrs:{"header":"Search"}},[_c('b-form-row',{staticClass:"justify-content-end"},[_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name /Email","label-for":"search"}},[_c('search',{attrs:{"placeholder":"Name, Email","search-query":_vm.searchQuery.keyword},on:{"update:searchQuery":function($event){return _vm.$set(_vm.searchQuery, "keyword", $event)},"update:search-query":function($event){return _vm.$set(_vm.searchQuery, "keyword", $event)},"refresh":_vm.refreshTable}})],1)],1),_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"6"}},[_c('custom-vue-select',{attrs:{"id":"approval-status","label":"Approval Status","placeholder":"Approval Status","value-reducer":function (option){ return option.value; },"options":[
            {text:'Approved',value:'accepted'},
            {text:'Pending',value:'pending'},
            {text:'Reject',value:'rejected'} ],"selected":_vm.requestStatusProxy,"multiple":"","close-on-select":false,"text-field":"text"},on:{"update:selected":function($event){_vm.requestStatusProxy=$event}}})],1),_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"6"}},[_c('custom-vue-select',{attrs:{"id":"published","label":"Publish Status","placeholder":"Publish Status","value-reducer":function (option){ return option.value; },"options":[
            {text:'All',value:null},
            {text:'Published',value:true},
            {text:'Not Published',value:false} ],"selected":_vm.searchQuery.published,"text-field":"text"},on:{"update:selected":function($event){return _vm.$set(_vm.searchQuery, "published", $event)}}})],1),_c('b-col',{staticClass:"mb-1 mt-md-2",attrs:{"md":"6"}},[_c('date-range-filter',{attrs:{"date-range":_vm.searchQuery.dateRange},on:{"update:dateRange":function($event){return _vm.$set(_vm.searchQuery, "dateRange", $event)},"update:date-range":function($event){return _vm.$set(_vm.searchQuery, "dateRange", $event)}}})],1),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"md":"2"}},[_c('search-button',{on:{"refresh":_vm.refreshTable}})],1)],1)],1),_c('spinner-loader',{attrs:{"loading":_vm.loading}}),_c('b-card',[_c('b-table',{ref:"table",attrs:{"id":"table","primary-key":"id","items":_vm.getOrgSuggestion,"fields":_vm.fields,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"show-empty":"","responsive":"","empty-text":"There is no matched result"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('b-link',{attrs:{"to":{name:'organization-suggestion-view',params:{id:item.id} }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"cell(created_at)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('b-dropdown',{attrs:{"id":"download-prayers-options","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showOriginalRequestModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v(" View Main Suggest")])],1),(!item.published)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showPublishModal(item)}}},[_c('feather-icon',{attrs:{"icon":"ToggleLeftIcon"}}),_vm._v(" Save and publish ")],1):_vm._e(),(item.approvalStatus.status === 'Pending')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showApproveModal(item)}}},[_c('feather-icon',{attrs:{"icon":"ToggleLeftIcon"}}),_vm._v(" Save to entities ")],1):_vm._e(),(item.approvalStatus.status === 'Pending')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showReasonModal(item)}}},[_c('feather-icon',{attrs:{"icon":"ToggleRightIcon"}}),_vm._v(" Reject ")],1):_vm._e(),(item.approvalStatus.status === 'Reject')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showViewReasonModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v(" View Rejection Reason ")])],1):_vm._e(),(_vm.$can('update','global-EntitiesController') && ['Pending','Approved'].includes(item.approvalStatus.status) && !item.published)?_c('b-dropdown-item',{attrs:{"to":{ name: 'organization-suggestion-edit', params:{ id: item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(" Edit ")])],1):_vm._e()],1)]}}])})],1),_c('b-modal',{attrs:{"id":"reason-modal","centered":"","title":"Reject Confirmation","hide-footer":""},on:{"hidden":function($event){_vm.reason=null}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('text-input',{attrs:{"id":"reason","name":"Reason","text":_vm.reason},on:{"update:text":function($event){_vm.reason=$event}}}),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",on:{"click":function($event){return _vm.$bvModal.hide('reason-modal')}}},[_vm._v(" Cancel Reject ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.rejectOrganizer)}}},[_vm._v(" Confirm Reject ")])],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"view-reason-modal","centered":"","title":"Rejection Reason","hide-footer":""},on:{"hidden":_vm.resetOrganization}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('text-input',{attrs:{"id":"reason","name":"Reason","disabled":"","text":_vm.rejectionReason},on:{"update:text":function($event){_vm.rejectionReason=$event}}})],1)],1),_c('b-modal',{attrs:{"id":"view-original-request-modal","centered":"","lazy":"","size":"lg","title":"Suggestion Original Request","hide-footer":""}},[_c('suggestion-form',{attrs:{"suggestion":_vm.suggestionOriginalRequest,"disabled":true}})],1),_c('b-modal',{attrs:{"id":"set-user-as-organizer-modal","centered":"","title":((_vm.publish ? 'Publish' : 'Approve') + " Confirmation"),"hide-footer":""}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"my-2",attrs:{"title":!_vm.approvedOrganization.business_email?'Provide email to set the user as admin':'',"disabled":!_vm.approvedOrganization.business_email || (_vm.publish && _vm.approvedOrganization.set_user_as_organizer)},model:{value:(_vm.approvedOrganization.set_user_as_organizer),callback:function ($$v) {_vm.$set(_vm.approvedOrganization, "set_user_as_organizer", $$v)},expression:"approvedOrganization.set_user_as_organizer"}},[_vm._v(" Set user as organization admin ")]),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",on:{"click":function($event){return _vm.$bvModal.hide('set-user-as-organizer-modal')}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.approveOrganization)}}},[_vm._v(" "+_vm._s(_vm.publish ? 'Publish' : 'Approve')+" ")])],1)],1)]}}])})],1),_c('pagination',{attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }