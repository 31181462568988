<template>
  <div>
    <spinner-loader :loading="loadingStatistics" />
    <b-card v-if="!loadingStatistics">
      <template #header>
        <h4>Statistics</h4>
      </template>
      <b-row>
        <b-col>
          <statistic-card-horizontal
            icon="UserCheckIcon"
            :statistic="statistics.totalSuggestions"
            statistic-title="Total"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            icon="UserPlusIcon"
            :statistic="statistics.pendingSuggestions"
            statistic-title="Pending"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            color="success"
            icon="UserCheckIcon"
            :statistic="statistics.publishedSuggestions"
            statistic-title="published"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            color="warning"
            icon="UserMinusIcon"
            :statistic="statistics.notPublishedSuggestions"
            statistic-title="Not published"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            icon="UserXIcon"
            color="danger"
            :statistic="statistics.rejectedSuggestions"
            statistic-title="Rejected"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Search -->
    <b-card header="Search">
      <b-form-row class="justify-content-end">
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Name /Email"
            label-for="search"
          >
            <search
              placeholder="Name, Email"
              :search-query.sync="searchQuery.keyword"
              @refresh="refreshTable"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <custom-vue-select
            id="approval-status"
            label="Approval Status"
            placeholder="Approval Status"
            :value-reducer="option=>option.value"
            :options="[
              {text:'Approved',value:'accepted'},
              {text:'Pending',value:'pending'},
              {text:'Reject',value:'rejected'},
            ]"
            :selected.sync="requestStatusProxy"
            multiple
            :close-on-select="false"
            text-field="text"
          />
        </b-col>

        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <custom-vue-select
            id="published"
            label="Publish Status"
            placeholder="Publish Status"
            :value-reducer="option=>option.value"
            :options="[
              {text:'All',value:null},
              {text:'Published',value:true},
              {text:'Not Published',value:false},
            ]"
            :selected.sync="searchQuery.published"
            text-field="text"
          />
        </b-col>

        <b-col
          md="6"
          class="mb-1 mt-md-2"
        >
          <date-range-filter :date-range.sync="searchQuery.dateRange" />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <!-- Spinner -->
    <spinner-loader :loading="loading" />

    <!-- Table -->
    <b-card>
      <b-table
        id="table"
        ref="table"
        primary-key="id"
        :items="getOrgSuggestion"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        show-empty
        responsive
        empty-text="There is no matched result"
      >
        <template #cell(name)="{item}">
          <div>
            <b-link :to="{name:'organization-suggestion-view',params:{id:item.id} }">
              {{ item.name }}
            </b-link>
          </div>
        </template>
        <template #cell(created_at)="{item}">
          <div class="text-nowrap">
            {{ item.created_at }}
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <b-dropdown
            id="download-prayers-options"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="showOriginalRequestModal(item)"
            >
              <feather-icon icon="EyeIcon" />
              <span> View Main Suggest</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!item.published"
              @click="showPublishModal(item)"
            >
              <feather-icon icon="ToggleLeftIcon" />
              Save and publish
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.approvalStatus.status === 'Pending'"
              @click="showApproveModal(item)"
            >
              <feather-icon icon="ToggleLeftIcon" />
              Save to entities
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.approvalStatus.status === 'Pending'"
              @click="showReasonModal(item)"
            >
              <feather-icon icon="ToggleRightIcon" />
              Reject
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.approvalStatus.status === 'Reject'"
              @click="showViewReasonModal(item)"
            >
              <feather-icon icon="EyeIcon" />
              <span> View Rejection Reason </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('update','global-EntitiesController') && ['Pending','Approved'].includes(item.approvalStatus.status) && !item.published"
              :to="{ name: 'organization-suggestion-edit', params:{ id: item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <!-- Reason Modal -->
    <b-modal
      id="reason-modal"
      centered
      title="Reject Confirmation"
      hide-footer
      @hidden="reason=null"
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <text-input
            id="reason"
            name="Reason"
            :text.sync="reason"
          />
          <div class="text-right">
            <b-button
              class="mr-1"
              @click="$bvModal.hide('reason-modal')"
            >
              Cancel Reject
            </b-button>
            <b-button
              variant="primary"
              @click="handleSubmit(rejectOrganizer)"
            >
              Confirm Reject
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="view-reason-modal"
      centered
      title="Rejection Reason"
      hide-footer
      @hidden="resetOrganization"
    >
      <b-form @submit.prevent>
        <text-input
          id="reason"
          name="Reason"
          disabled
          :text.sync="rejectionReason"
        />
      </b-form>
    </b-modal>

    <b-modal
      id="view-original-request-modal"
      centered
      lazy
      size="lg"
      title="Suggestion Original Request"
      hide-footer
    >
      <suggestion-form
        :suggestion="suggestionOriginalRequest"
        :disabled="true"
      />
    </b-modal>

    <b-modal
      id="set-user-as-organizer-modal"
      centered
      :title="`${ publish ? 'Publish' : 'Approve' } Confirmation`"
      hide-footer
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <b-checkbox
            v-model="approvedOrganization.set_user_as_organizer"
            v-b-tooltip.hover
            class="my-2"
            :title="!approvedOrganization.business_email?'Provide email to set the user as admin':''"
            :disabled="!approvedOrganization.business_email || (publish && approvedOrganization.set_user_as_organizer)"
          >
            Set user as organization admin
          </b-checkbox>
          <div class="text-right">
            <b-button
              class="mr-1"
              @click="$bvModal.hide('set-user-as-organizer-modal')"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              @click="handleSubmit(approveOrganization)"
            >
              {{ publish ? 'Publish' : 'Approve' }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Pagination -->
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import paginationData from '@/common/compositions/common/paginationData'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'
import SuggestionForm from './SuggestionForm.vue'

export default {
  name: 'SuggestionList',
  components: {
    Pagination,
    SpinnerLoader,
    Search,
    SearchButton,
    TextInput,
    DateRangeFilter,
    CustomVueSelect,
    SuggestionForm,
    StatisticCardHorizontal,
  },
  data() {
    return {
      searchQuery: {
        keyword: null,
        requestStatus: ['accepted', 'pending'],
        dateRange: [],
        published: null,
      },
      loading: true,
      loadingStatistics: true,
      statistics: {},
      reason: '',
      suggestionOriginalRequest: { media_files: [], address: {} },
      rejectedOrganization: { approvalStatus: {} },
      approvedOrganization: {},
      publish: false,
    }
  },
  computed: {
    requestStatusProxy: {
      get() {
        return this.searchQuery.requestStatus
      },
      set(value) {
        this.$set(this.searchQuery, 'requestStatus', value)
      },
    },
    rejectionReason() {
      return this.rejectedOrganization.approvalStatus?.note || 'No Reason Added'
    },
    fields() {
      return [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        {
          key: 'address',
          formatter: v => Object.values(v || {}).join(' '),
        },
        { key: 'classification', sortable: true },
        { key: 'subclassifications', sortable: true, label: 'subclass' },
        { key: 'created_at', sortable: true, label: 'suggestion date' },
        { key: 'published', formatter: v => (v ? 'Published' : 'Not Published'), sortable: true },
        { key: 'approvalStatus', formatter: v => v.status, sortable: true },
        { key: 'actions' },
      ]
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return { successfulOperationAlert, confirmOperation, pagination }
  },
  created() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      return this.$entities.get('/internalops/suggestions/statistics', {
        params: {
          classifications: this.$attrs['suggestion-type'],
        },
      }).then(res => {
        this.statistics = res.data.data
      }).finally(() => {
        this.loadingStatistics = false
      })
    },
    getOrgSuggestion(ctx) {
      return this.$entities.get('/internalops/suggestions', {
        params: {
          page: this.pagination.currentPage,
          classifications: this.$attrs['suggestion-type'],
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          date_from: this.searchQuery.dateRange[0],
          date_to: this.searchQuery.dateRange[1],
          keyword: this.searchQuery.keyword,
          requestStatus: this.searchQuery.requestStatus.join(',') || null,
          published: this.searchQuery.published,
        },
      }).then(res => {
        const suggestions = res.data.data
        this.pagination.totalRows = res.data.pagination.total

        return suggestions || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    resetOrganization() {
      this.$set(this.rejectedOrganization, 'approvalStatus', {})
    },
    showApproveModal(item) {
      this.$bvModal.show('set-user-as-organizer-modal')
      this.approvedOrganization = item
      this.publish = false
    },
    showPublishModal(item) {
      this.$bvModal.show('set-user-as-organizer-modal')
      this.approvedOrganization = item
      this.publish = true
    },
    approveOrganization() {
      this.$entities.post(`/internalops/organizer/${this.approvedOrganization.id}/accept`, this.approvedOrganization, { params: { activate: this.publish } }).then(() => {
        this.successfulOperationAlert(`${this.$attrs['suggestion-type']} is ${this.publish ? 'published' : 'saved to entities'} successfully`)
        this.$bvModal.hide('set-user-as-organizer-modal')
        this.refreshTable()
      })
    },
    showOriginalRequestModal(item) {
      this.$bvModal.show('view-original-request-modal')

      this.suggestionOriginalRequest = {
        ...item.originalRequest,
        about: item.originalRequest.description,
        set_user_as_organizer: item.set_user_as_organizer,
        media_files: item.originalRequest.mediaFiles.map(file => `${process.env.VUE_APP_ENTITIES_IMAGES_BASE_URL}${file}`),
        user: item.submitted_by,
        address: {
          country: item.originalRequest.country,
          street: item.originalRequest.street,
          city: item.originalRequest.city,
          state: item.originalRequest.state,
          postal_code: item.originalRequest.postal_code,
          lat: item.originalRequest.lat,
          lng: item.originalRequest.lng,
        },
      }
    },
    showViewReasonModal(item) {
      this.$set(this.rejectedOrganization.approvalStatus, 'note', item.approvalStatus.note)
      this.$bvModal.show('view-reason-modal')
    },
    showReasonModal(item) {
      this.$bvModal.show('reason-modal')
      this.rejectedOrganization = item
    },
    rejectOrganizer() {
      this.$entities.post(`/internalops/organizer/${this.rejectedOrganization.id}/reject`, { reason: this.reason }).then(() => {
        this.successfulOperationAlert(`${this.$attrs['suggestion-type']} is rejected successfully`)
        this.$bvModal.hide('reason-modal')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
.swal-wide{
    width:40em !important;
}
</style>
